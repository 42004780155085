import React from 'react';
import PropTypes from 'prop-types';

import {Column, Container, Row} from '../../../layout/Grid';
import RewardTeaser from '../RewardTeaser';

const RewardsOverview = ({rewards}) => {
    return (
        <Container width="wide">
            <Row>
                {rewards.map((reward) => (
                    <Column key={reward.id} xs={12} sm={6} md={6} lg={4}>
                        <RewardTeaser
                            title={reward.teaserHeadline}
                            description={reward.teaserDescription}
                            image={reward.image}
                            points={reward.points}
                            route={reward.route}
                        />
                    </Column>
                ))}
            </Row>
        </Container>
    );
};

RewardsOverview.propTypes = {
    rewards: PropTypes.arrayOf(
        PropTypes.shape({
            teaserHeadline: PropTypes.string,
            teaserDescription: PropTypes.string,
            image: PropTypes.string,
            points: PropTypes.number,
            route: PropTypes.string,
        })
    ),
};

export default RewardsOverview;
