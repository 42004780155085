import styled from 'styled-components';
import {color, font} from '../../../../styles/variables';
import Link from '../../../components/Link';

export const LinkWrapper = styled(Link)`
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    background-color: ${color.white};
    color: ${color.black};
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15);

    * {
        max-width: 100%;
    }

    .ap-text {
        background-image: none;
    }

    &:focus,
    &:hover {
        transform: translate(0, -2px) scale(1.1);
        box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.25);
    }
`;

export const ImageWrapper = styled.div`
    position: relative;
    padding: 3rem 1rem 1rem 1rem;

    img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto;
    }
`;

export const ContentWrapper = styled.div`
    padding: 1rem;
    text-align: center;
`;

export const MetaWrapper = styled.div`
    position: absolute;
    top: 1rem;
    right: 1rem;
    display: flex;
    box-sizing: border-box;
`;

export const Points = styled.span`
    font-weight: ${font.weight.bold};
    margin-left: 0.5em;
`;
