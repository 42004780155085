import React from 'react';
import {useSelector} from 'react-redux';

import Layout from '../ui/layout/Layout';
import {Section, Container, Row, Column} from '../ui/layout/Grid';
import Headline from '../ui/components/Headline';
import lang from '../lang/pages/praemien.lang';
import profileLang from '../lang/pages/profil.lang';
import trainingsLang from '../lang/pages/trainings.lang';
import Paragraph from '../ui/components/Paragraph';
import ShopWindow from '../ui/layout/ShopWindow';
import {
    selectById as selectTrainingsById,
    suggest as suggestTrainings,
} from '../model/training';
import {
    selectAllOfTypeProduct as selectAllProductRewards,
    selectAllOfTypeVoucher as selectAllVoucherRewards,
} from '../model/reward';
import MultiStepPersonalization from '../ui/domains/profile/MultiStepPersonalization';
import {
    selectUserCategories,
    selectIsPersonalizationFinished,
} from '../model/profile';
import {selectIsLoggedIn} from '../model/authentication';
import {IfNotLoggedIn, IfLoggedIn} from '../ui/utils/AuthenticationSwitch';
import {routes} from '../model/navigation';
import Link from '../ui/components/Link';
import Button from '../ui/components/Button';
import RewardsOverview from '../ui/domains/reward/RewardsOverview';
import Divider from '../ui/components/Divider';
import {color} from '../styles/variables';
import {scrollToAnchorStart} from '../utils/scroll-utils';

import TrainingTeaser from '../ui/domains/training/Teaser';

const Praemien = () => {
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const userCategories = useSelector(selectUserCategories);
    const trainingsById = useSelector(selectTrainingsById);
    const {
        numTrainingsMatchingInterest,
        numTrainingsNotMatchingInterest,
        suggestedTrainings,
    } = suggestTrainings(trainingsById, isLoggedIn, userCategories);
    const isPersonalizationFinished = useSelector(
        selectIsPersonalizationFinished
    );
    const productRewards = useSelector(selectAllProductRewards);
    const voucherRewards = useSelector(selectAllVoucherRewards);
    return (
        <Layout>
            <Section>
                <Container width="narrow" className="text-center">
                    <Headline type="h1">{lang.deinePraemien}</Headline>
                    <Paragraph>{lang.paragraph1}</Paragraph>
                </Container>
                <Container width="narrow" className="text-center">
                    <Row>
                        <Column xs={6} md={4} offset={{md: 2}}>
                            <Button
                                label={lang.sachPraemien}
                                onClick={(event) => {
                                    event.preventDefault();
                                    scrollToAnchorStart('sachpraemien-section');
                                }}
                            />
                        </Column>
                        <Column xs={6} md={4}>
                            <Button
                                label={lang.gutscheinPraemien}
                                onClick={(event) => {
                                    event.preventDefault();
                                    scrollToAnchorStart(
                                        'gutscheinpraemien-section'
                                    );
                                }}
                            />
                        </Column>
                    </Row>
                </Container>
            </Section>
            <Section anchor="sachpraemien-section">
                <Container width="narrow" className="text-center">
                    <Headline type="h2">{lang.sachPraemien}</Headline>
                </Container>
                <RewardsOverview rewards={productRewards} />
            </Section>
            <Section anchor="gutscheinpraemien-section">
                <Container width="narrow" className="text-center">
                    <Headline type="h2">{lang.gutscheinPraemien}</Headline>
                </Container>
                <RewardsOverview rewards={voucherRewards} />
            </Section>
            <Section theme="blue">
                <Container width="wide" className="text-center">
                    <Row>
                        <Column md={8} lg={6} offset={{md: 2, lg: 3}}>
                            {!isLoggedIn ? (
                                <>
                                    <Headline type="h3">
                                        {lang.punkteSammeln}
                                    </Headline>
                                    <Paragraph>{lang.paragraph2}</Paragraph>
                                </>
                            ) : isPersonalizationFinished ? (
                                // logged in and personalization finished:
                                <>
                                    <Headline type="h3">
                                        {lang.punkteSammeln}
                                    </Headline>
                                    <Paragraph>
                                        {trainingsLang.schaufensterParagraph(
                                            numTrainingsMatchingInterest,
                                            numTrainingsNotMatchingInterest
                                        )}
                                    </Paragraph>
                                </>
                            ) : (
                                // logged in and personalization not finished:
                                <>
                                    <Headline type="h3">
                                        {profileLang.personalisierung.headline}
                                    </Headline>
                                    <Paragraph>
                                        {profileLang.personalisierung.paragraph}
                                    </Paragraph>
                                </>
                            )}
                        </Column>
                    </Row>
                    <Row>
                        <Column xs={12}>
                            <Divider dashed color={color.white} />
                        </Column>
                    </Row>
                </Container>
                <Container width="wide">
                    <IfNotLoggedIn>
                        <ShopWindow teasers={suggestedTrainings}>
                            {(training) => (
                                <TrainingTeaser
                                    title={training.title}
                                    type={training.type.name}
                                    image={training.image}
                                    points={training.points}
                                    duration={training.duration}
                                    completed={training.completed}
                                    route={training.route}
                                    isReleased={training.isReleased}
                                />
                            )}
                        </ShopWindow>
                    </IfNotLoggedIn>
                    <IfLoggedIn>
                        {isPersonalizationFinished ? (
                            <ShopWindow teasers={suggestedTrainings}>
                                {(training) => (
                                    <TrainingTeaser
                                        title={training.title}
                                        type={training.type.name}
                                        image={training.image}
                                        points={training.points}
                                        duration={training.duration}
                                        completed={training.completed}
                                        route={training.route}
                                        isReleased={training.isReleased}
                                    />
                                )}
                            </ShopWindow>
                        ) : (
                            <MultiStepPersonalization />
                        )}
                    </IfLoggedIn>
                </Container>
                <IfLoggedIn>
                    <Container width="narrow" className="text-center">
                        <Link
                            showAsButton
                            label={lang.zurTrainingskollektion}
                            href={routes.trainings}>
                            Zur Trainingskollektion
                        </Link>
                    </Container>
                </IfLoggedIn>
            </Section>
        </Layout>
    );
};

export default Praemien;
