import React from 'react';

import {parseHtmlToReact} from '../../../../utils/html-react-parser';
import PropTypes from 'prop-types';

import {
    ContentWrapper,
    ImageWrapper,
    MetaWrapper,
    Points,
    LinkWrapper,
} from './styles';
import Paragraph from '../../../components/Paragraph';
import Headline from '../../../components/Headline';
import Icon from '../../../components/Icon';
import Image from '../../../components/Image';
import {makeUrl} from '../../../../model/backend-api';
import {color} from '../../../../styles/variables';

const htmlNodeReplacements = {
    p: Paragraph,
};

const RewardTeaser = ({image, title, description, points, route}) => {
    return (
        <LinkWrapper className="ap-teaser" href={route}>
            <ImageWrapper>
                <Image
                    height="220px"
                    width="auto"
                    src={makeUrl(image)}
                    alt={title}
                />
                <MetaWrapper>
                    <Icon
                        name="starFilled"
                        size="1.5rem"
                        color={color.petrol}
                    />
                    <Points>{points}</Points>
                </MetaWrapper>
            </ImageWrapper>
            <ContentWrapper>
                <Headline type="h6" fontWeight="bold">
                    {title}
                </Headline>
                {parseHtmlToReact(description, htmlNodeReplacements)}
            </ContentWrapper>
        </LinkWrapper>
    );
};

RewardTeaser.propTypes = {
    image: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    points: PropTypes.number,
    route: PropTypes.string,
};

export default RewardTeaser;
