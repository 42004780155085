import React from 'react';

import Link from '../../ui/components/Link';
import {routes} from '../../model/navigation';

export default {
    deinePraemien: (
        <i>
            Deine Prämien <strong>im Überblick</strong>
        </i>
    ),
    paragraph1:
        'In diesem Bereich kannst Du Deine Punkte gegen schicke Prämien eintauschen. Schau Dich gerne ganz in Ruhe um.',
    sachPraemien: 'Sachprämien',
    gutscheinPraemien: 'Gutscheine',
    punkteSammeln: (
        <>
            <strong>Jetzt Punkte sammeln – </strong>
            <i>und exklusive Prämien erwerben!</i>
        </>
    ),
    paragraph2:
        'Der Wissenslaufsteg ist eröffnet: Perfektioniere jetzt schon Deine Fähigkeiten mit unseren Trainings und sichere Dir Punkte für exklusive Prämien. ',
    zurTrainingskollektion: 'Zur Trainingskollektion',
    orderButton: 'Jetzt bestellen',
    orderFailure: {
        hl: 'Prämienbestellung fehlgeschlagen.',
        msgNotEnoughPoints:
            'Du hast nicht genügend Punkte, um diese Prämie zu bestellen.',
    },
    lightboxHeadline: <strong>Auswahl bestätigen</strong>,
    lightboxVoucherText:
        'Wir werden Dir Deinen Gutschein an Deine E-Mail-Adresse versenden:',
    lightboxRewardText: 'Wir senden Dir Deine Prämie an Deine Apotheke.',
    confirm: 'Bestätigen',
    imgAlt: 'Grafik:  Prämie.',
    checkPharmacy: (
        <>
            Wenn dies nicht Deine Apotheke ist, prüfe bitte{' '}
            <Link href={routes.profil}>hier</Link> Deine Daten oder wende Dich
            über das Kontaktformular an uns.
        </>
    ),
    checkEmail: (
        <>
            Wenn dies nicht Deine E-Mail-Adresse ist, prüfe bitte{' '}
            <Link href={routes.profil}>hier</Link> Deine Daten.
        </>
    ),
    voucherModal: {
        headline: <strong>Das hast Du Dir verdient!</strong>,
        thanks:
            'Deine Bestellung war erfolgreich. Dein Gutschein sollte Dich in den nächsten 60 Minuten erreichen. Schaue bitte auch in Deinen Spam-Ordner.',
    },
    productModal: {
        headline: 'Deine Prämie wird versendet!',
        thanks: (
            <>
                Vielen Dank! <br /> Deine Prämie ist jetzt auf dem Weg zu Dir.
            </>
        ),
    },
    moreRewardsHeadline: (
        <>
            <strong>Weitere Prämien,</strong>
            <br />
            <i>die gut zu Dir passen</i>
        </>
    ),
    moreRewardsSub:
        'Hier findest Du unsere Vorschläge für weitere schicke Prämien, die Dir gefallen könnten. ',
};
